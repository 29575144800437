import React, { useRef } from 'react'
import UnauthenticatedLayout from '../components/unauthenticatedLayout'
import { Button, Box, TextInput } from "grommet";
import { colors } from "../theme";
import styled from 'styled-components'
import { useContainer } from 'unstated-next';
import { CurrentUserState } from '../components/state/CurrentUserState';

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: 100vw;
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledBox = styled(Box)`
  width: 350px;
  > :first-child {
    color: ${colors.indigo};
    text-transform: uppercase;
    letter-spacing: 1.1;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 30px;
  }
  
  > * {
    margin-bottom: 15px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const LoginPage = () => {
  const passwordRef = useRef()
  const emailRef = useRef()
  const { login } = useContainer(CurrentUserState)

  const submitLogin = async () => {
    await login(emailRef.current.value, passwordRef.current.value)
  }

  return <UnauthenticatedLayout>
    <Wrapper>
      <StyledBox background="#fff" elevation="medium" pad="medium" round="small">
      <div>Login as a tutor</div>
        <TextInput ref={emailRef} type='email' defaultValue={''} />
        <TextInput ref={passwordRef} type='password' defaultValue={''} onKeyPress={e => e.which === 13 && submitLogin()} />
        <Button onClick={submitLogin} primary color={colors.indigo}>Submit</Button>
      </StyledBox>
    </Wrapper>
  </UnauthenticatedLayout>
}

export default LoginPage
