/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './UI/Header'
import styled from 'styled-components'
import './index.css'
import { useContainer } from 'unstated-next'
import { CurrentUserState } from './state/CurrentUserState'
import { navigate } from 'gatsby'

const Content = styled.div`
`

const UnauthenticatedLayout = ({ children }) => {
  const state = useContainer(CurrentUserState)

  useEffect(() => {
    if (state.isAuthenticated === true) navigate('/')
  }, [state.isAuthenticated])

  if (state.isAuthenticated) return null

  return (
    <>
      <Header />
      <Content>
        {children}
      </Content>
    </>
  )
}

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default UnauthenticatedLayout
